import { CallApi } from '../../shared/services/api.service';
import CONFIG from '../../configuration';

/**
 * @description This is used to have the backend upload all the audit's images to a zip file in S3 bucket and send an email to the user with a download link to that zipped file
 * @param auditName name of audit (to be used to name the final zip file)
 * @param language preferred anguage of the user
 * @param auditName name of the audit
 * @param userEmail email address of the requesting user
 * @param userName first name of the requesting user
 */
export const downloadAuditImages = async (auditId, language, auditName, userEmail, userName) => {
    const handleRes = (res) => {
        if (res.success) {
            return { success: true, message: res.message, images: res.response.images };
        }
        return { success: false, message: res.message };
    };

    return handleRes(
        await CallApi(
            'GET',
            CONFIG.getAuditImages,
            {},
            { auditId, auditName, userEmail, userName },
            '',
            '',
            this,
            language
        )
    );
};
