// heightsApi.js (or your chosen filename)
import Axios from 'axios';
import { getData } from '../shared/utils/storage.util';
import CONFIG from '../configuration';

export const fetchHeightsTable = async (audit_id) => {
    try {
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`
            }
        };

        const response = await Axios.get(`${CONFIG.getHeightsTable}?audit_id=${audit_id}`, config);

        const responseData = response.data.response;

        if (response.data.success && typeof responseData === 'object' && responseData !== null) {
            const heightsTableData = responseData.map((item) => ({
                audit_id: item.audit_id,
                auditor_comments: item.auditor_comments,
                reviewer_comments: item.reviewer_comments,
                long_heading: item.long_heading,
                long_ques: item.long_ques,
                operation_numb: item.operation_numb,
                reviewer_remark: item.reviewer_remark,
                task_id: item.task_id,
                short_ques: item.short_ques
            }));

            return heightsTableData;
        } else {
            console.warn('No data found for this audit_id');
            return null;
        }
    } catch (error) {
        console.error('Error fetching heights table:', error);
        return null;
    }
};
