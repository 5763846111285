import Axios from 'axios';
import { getData } from '../shared/utils/storage.util';
import CONFIG from '../configuration';
import AlertModal from '../shared/modals/AlertModal';

export const fetchMeasurementsTable = async (audit_id) => {
    try {
        const config = {
            headers: {
                authorization: `Bearer ${getData('token')}`
            },
            params: {
                auditId: audit_id
            }
        };

        const response = await Axios.get(`${CONFIG.getMeasurementsTable}`, config);

        if (response.status === 200 && response.data.success) {
            const data = response.data.response;
            return data;
        } else {
            console.error('Error fetching measurements:', response);
            AlertModal.showAlert('Error fetching measurements.');
        }
    } catch (error) {
        console.error('Error fetching measurements from database:', error);
        AlertModal.showAlert('An error occurred while fetching data.');
    }
};
