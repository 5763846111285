/**
 * @author Rehan Rizvi
 * @file QuesList Component
 * @flow
 */
import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import CONFIG from '../../configuration';
import { getData } from '../../shared/utils/storage.util';
import AlertModal from '../../shared/modals/AlertModal';
import LANGUAGECONST from '../../shared/constants/language';

const LimitInput = ({ limits, questions, handleLimitChange }) => {
    return (
        <div className="table-outer tabcontent active">
            <div className="table-responsive">
                <table className="gen-audt-mch-sts">
                    <thead>
                        <tr>
                            <th style={{ width: '30%' }}>Short Description</th>
                            <th style={{ width: '30%' }}>Long Description</th>
                            <th style={{ width: '20%' }}>Min Limits</th>
                            <th style={{ width: '20%' }}>Max Limits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions.length === 0 ? (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                    No question found
                                </td>
                            </tr>
                        ) : (
                            questions.map((question, index) => (
                                <tr key={index}>
                                    <td>{question.description}</td>
                                    <td>{question.long_description}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={
                                                limits[`${question.description}_min`] !== undefined
                                                    ? limits[`${question.description}_min`]
                                                    : ''
                                            }
                                            max="999999"
                                            onChange={(e) =>
                                                handleLimitChange(
                                                    `${question.description}_min`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={
                                                limits[`${question.description}_max`] !== undefined
                                                    ? limits[`${question.description}_max`]
                                                    : ''
                                            }
                                            max="999999"
                                            onChange={(e) =>
                                                handleLimitChange(
                                                    `${question.description}_max`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

class QuesListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            QuesList: [],
            currentTabName: 0,
            heading: [],
            dataBassedOnTab: {},
            PM_headingArr: ['PM Quality Assessment', 'Preventative Maintenance'],
            MH_headingArr: ['General Audit', 'Machine Health'],
            NO_PM_MH_heading: ['General Audit', 'PM Quality Assessment'],
            DV_heading: ['Diagnostic Visit'],
            INJECT_headingArr: ['Preventative Maintenance'],
            ALL_tabs: [
                'General Audit',
                'PM Quality Assessment',
                'Preventative Maintenance',
                'Machine Health'
            ],
            limits: {},
            questions: []
        };
    }

    fetchLimits = async (assetName = this.props.assetName) => {
        try {
            const config = {
                headers: {
                    authorization: `Bearer ${getData('token')}`
                }
            };

            const response = await Axios.get(
                `${CONFIG.getLimits}?assetName=${encodeURIComponent(assetName)}`,
                config
            );

            if (response.data.success && response.data.response.length > 0) {
                const limitsData = {};
                const fetchedQuestions = response.data.response;
                response.data.response.forEach((question) => {
                    const limitKeyMin = `${question.description}_min`;
                    const limitKeyMax = `${question.description}_max`;

                    limitsData[limitKeyMin] = question.min_limit || '';
                    limitsData[limitKeyMax] = question.max_limit || '';
                });
                this.setState({ limits: limitsData, questions: fetchedQuestions });
            }
        } catch (error) {
            console.error('Error fetching limits:', error);
        }
    };

    handleLimitSubmit = async () => {
        const dataToSubmit = Object.entries(this.state.limits).reduce((acc, [key, value]) => {
            const [description, type] = key.split('_');
            if (!acc[description]) {
                acc[description] = { description, min_limit: null, max_limit: null };
            }
            acc[description][`${type}_limit`] = value;
            return acc;
        }, {});

        const formattedData = {
            line: this.props.assetName,
            limits: Object.values(dataToSubmit),
            selectDV: this.props.selectDV
        };

        if (this.props.onLimitsChange) {
            this.props.onLimitsChange(formattedData);
        }

        return true;
    };

    handleLimitChange = (description, value) => {
        this.setState((prevState) => ({
            limits: {
                ...prevState.limits,
                [description]: value ? Number(value) : ''
            }
        }));
    };
    /**
     * @description This function call once the component load at first time and call the function to handle the heading.
     */
    componentDidMount = () => {
        this.getHeading(this.props);
    };
    componentDidUpdate(prevProps) {
        if (this.props.heights && !prevProps.heights) {
            this.fetchLimits(this.props.assetName);
        }
    }

    /**
     * @description This function call when the props update.
     * @param nextProps NextProps is the updated props
     */
    componentWillReceiveProps = (nextProps) => {
        this.getHeading(nextProps);
        if (nextProps.assetName !== this.props.assetName) {
            this.fetchLimits(nextProps.assetName);
        }
    };

    /**
     * @description This function used to get the heading sets value to the state variable on the basis of activity and tab.
     * @param updatedProps This is updated props.
     */
    getHeading = (updatedProps) => {
        let {
            QuesList,
            assetsType,
            activity,
            selectMH,
            assetName,
            selectDV,
            lineType,
            updatedQuesFlag,
            heights
        } = updatedProps;
        let {
            PM_headingArr,
            MH_headingArr,
            DV_heading,
            NO_PM_MH_heading,
            INJECT_headingArr,
            ALL_tabs
        } = this.state;
        let heading = [],
            currentHeading = [],
            dataBassedOnTab = {};
        QuesList.map((data, index) => {
            if (
                data.activity &&
                !heading.includes(data.long_heading) &&
                data.activity === activity
            ) {
                heading.push(data.long_heading);
            }
            if (!data.activity && !heading.includes(data.long_heading)) {
                heading.push(data.long_heading);
            }
            if (dataBassedOnTab[data.long_heading]) {
                dataBassedOnTab[data.long_heading].push(data);
            } else {
                dataBassedOnTab[data.long_heading] = [data];
            }
        });
        currentHeading =
            assetsType == '3'
                ? activity.includes(' PM') && selectMH
                    ? ALL_tabs
                    : activity.includes(' PM') && !selectMH
                    ? PM_headingArr
                    : activity == 'None' && selectMH
                    ? MH_headingArr
                    : activity == 'None' && selectDV
                    ? heights
                        ? [...DV_heading, 'Heights']
                        : DV_heading
                    : !activity.includes(' PM') && !selectMH
                    ? NO_PM_MH_heading
                    : heading
                : heading;

        this.setState(
            {
                heading: currentHeading,
                dataBassedOnTab,
                QuesList,
                currentTabName: currentHeading[0]
            },
            () => {
                if (!updatedQuesFlag) {
                    this.upadateQues();
                }
            }
        );
    };

    /**
     * @description This function used to get updated questions array based on tab.
     */
    upadateQues = () => {
        let { assetsType, activity, selectMH } = this.props;
        let { heading, dataBassedOnTab } = this.state;
        let checkRepeat =
            (assetsType == '3' || assetsType == '4' || assetsType == '5') &&
            (activity.includes(' PM') || selectMH)
                ? true
                : false;
        let updateQuesArr = [];
        for (let key in dataBassedOnTab) {
            if (heading.indexOf(key) > -1) {
                let quesArr =
                    checkRepeat && key === 'Preventative Maintenance'
                        ? this.getUniqueQuesArr(dataBassedOnTab[key])
                        : dataBassedOnTab[key];
                updateQuesArr = updateQuesArr.concat(quesArr);
            }
        }
        if (updateQuesArr.length > 0) {
            this.props.updateQuestionArr(updateQuesArr);
        }
    };

    /**
     * @description This function used to get the unique bassde on activity.
     * @param quesArr This is question array.
     */
    getUniqueQuesArr = (quesArr) => {
        let { activity } = this.props;
        let arr = quesArr.filter((data) => data.activity === activity);
        return arr;
    };

    /**
     * @description This function used to hande the tab change.
     * @param currentTabName Selected tab name.
     */
    tabChange = (currentTabName) => {
        this.setState({ currentTabName });
    };

    /**
     * @description This function updates the current tab value.
     * @param event It has selected event value.
     */
    tabChangeName = (event) => {
        this.setState({ currentTabName: event.target.value });
    };

    /**
     * @description This function used to show the long questions on the basis of heading.
     */
    getLongQues = () => {
        let { currentTabName, heading, dataBassedOnTab } = this.state;
        return (
            <div className="work-order-tabs-outer">
                <div className="work-order-tabs">
                    {heading.map((tab, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn-wrk-ordr  tablinks
                ${currentTabName == tab ? 'active' : ''}`}
                            onClick={() => this.tabChange(tab)}>
                            {tab !== 'WORK ORDERS - PM' ? tab.toLowerCase() : 'Work Orders - PM'}
                        </button>
                    ))}
                </div>
                <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                    {heading.map((data, index) => (
                        <option key={index} value={data}>
                            {data !== 'WORK ORDERS - PM' ? data.toLowerCase() : 'Work Orders - PM'}
                        </option>
                    ))}
                </select>
                <div className="table-outer tabcontent active">
                    <ul className="rpair-slctn">
                        {dataBassedOnTab &&
                            dataBassedOnTab[currentTabName] &&
                            dataBassedOnTab[currentTabName].map((data, index) => (
                                <li key={index}>{data.long_ques}</li>
                            ))}
                    </ul>
                </div>
            </div>
        );
    };

    /**
     * @description This function used to show the short and long questions on the basis of heading.
     */
    getShortAndLongQues = (assetName) => {
        let { currentTabName, heading, dataBassedOnTab } = this.state;
        let { activity, selectMH } = this.props;
        let checkRepeat = activity.includes(' PM') || selectMH ? true : false;

        return (
            <div className="work-order-tabs-outer blower-tabs-outer">
                <div className="blower-tabs ">
                    {heading.map((tab, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn-blowers gnrl-adt  tablinks
                ${currentTabName == tab ? 'active' : ''}`}
                            onClick={() => this.tabChange(tab)}>
                            {tab}
                        </button>
                    ))}
                </div>
                <select className="tab-select" onClick={(e) => this.tabChangeName(e)}>
                    {heading.map((data, index) => (
                        <option key={index} value={data}>
                            {data}
                        </option>
                    ))}
                </select>
                {currentTabName !== 'Heights' && (
                    <div className="table-outer tabcontent active">
                        <div className="table-responsive">
                            <table className="gen-audt-mch-sts">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '40%' }}>Location/Summary/Component</th>
                                        <th style={{ width: '60%' }}>Evaluation Criteria</th>
                                    </tr>
                                    {dataBassedOnTab &&
                                        dataBassedOnTab[currentTabName] &&
                                        dataBassedOnTab[currentTabName].map((data, index) => {
                                            if (
                                                (checkRepeat &&
                                                    ((currentTabName ===
                                                        'Preventative Maintenance' &&
                                                        data.activity == activity) ||
                                                        (currentTabName === 'Machine Health' &&
                                                            selectMH))) ||
                                                !checkRepeat ||
                                                (currentTabName != 'Preventative Maintenance' &&
                                                    currentTabName != 'Machine Health')
                                            ) {
                                                return (
                                                    <tr key={index}>
                                                        <td>{data.short_ques}</td>
                                                        <td>{data.long_ques}</td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {currentTabName === 'Heights' && (
                    <LimitInput
                        assetName={this.props.assetName}
                        limits={this.state.limits}
                        handleLimitChange={this.handleLimitChange}
                        questions={this.state.questions}
                    />
                )}
            </div>
        );
    };

    render() {
        let { assetsType, utility_static_ques, assetName } = this.props;
        let { currentTabName } = this.state;
        let showShortAndLongQues = assetsType == '1' || assetsType == '2' ? false : true;
        return (
            <form action="" id="">
                <div className="work-order-wrap">
                    <div className="headiong-outer">
                        <h4>Audit Tasks / Questions</h4>
                    </div>

                    {currentTabName === 'General Utilities' ||
                    (currentTabName === 'General Facilities' &&
                        (utility_static_ques === 'Electrical' ||
                            utility_static_ques === 'Carbon Towers' ||
                            utility_static_ques === 'RO' ||
                            utility_static_ques === 'Boiler' ||
                            utility_static_ques === 'Mineral Skid' ||
                            utility_static_ques === 'Ozone Skid'))
                        ? this.getLongQues()
                        : showShortAndLongQues
                        ? this.getShortAndLongQues()
                        : this.getLongQues()}
                </div>
            </form>
        );
    }
}

export default QuesListComp;
